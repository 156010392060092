// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-cookies-en-tsx": () => import("./../../../src/pages/cookies.en.tsx" /* webpackChunkName: "component---src-pages-cookies-en-tsx" */),
  "component---src-pages-cookies-it-tsx": () => import("./../../../src/pages/cookies.it.tsx" /* webpackChunkName: "component---src-pages-cookies-it-tsx" */),
  "component---src-pages-index-backup-tsx": () => import("./../../../src/pages/index.backup.tsx" /* webpackChunkName: "component---src-pages-index-backup-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-en-tsx": () => import("./../../../src/pages/privacy.en.tsx" /* webpackChunkName: "component---src-pages-privacy-en-tsx" */),
  "component---src-pages-privacy-it-tsx": () => import("./../../../src/pages/privacy.it.tsx" /* webpackChunkName: "component---src-pages-privacy-it-tsx" */),
  "component---src-pages-product-en-tsx": () => import("./../../../src/pages/product.en.tsx" /* webpackChunkName: "component---src-pages-product-en-tsx" */),
  "component---src-pages-product-it-tsx": () => import("./../../../src/pages/product.it.tsx" /* webpackChunkName: "component---src-pages-product-it-tsx" */),
  "component---src-pages-products-en-tsx": () => import("./../../../src/pages/products.en.tsx" /* webpackChunkName: "component---src-pages-products-en-tsx" */),
  "component---src-pages-products-health-en-tsx": () => import("./../../../src/pages/products/health.en.tsx" /* webpackChunkName: "component---src-pages-products-health-en-tsx" */),
  "component---src-pages-products-health-it-tsx": () => import("./../../../src/pages/products/health.it.tsx" /* webpackChunkName: "component---src-pages-products-health-it-tsx" */),
  "component---src-pages-products-it-tsx": () => import("./../../../src/pages/products.it.tsx" /* webpackChunkName: "component---src-pages-products-it-tsx" */),
  "component---src-pages-products-taste-en-tsx": () => import("./../../../src/pages/products/taste.en.tsx" /* webpackChunkName: "component---src-pages-products-taste-en-tsx" */),
  "component---src-pages-products-taste-it-tsx": () => import("./../../../src/pages/products/taste.it.tsx" /* webpackChunkName: "component---src-pages-products-taste-it-tsx" */)
}

